/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';

const FormSelect = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let init = function() {
        $( '.form-field > select' )
            .wrap( '<div class="select is-select-full"></div>' );

        $( '.form-field > select, .is-select-full' ).show();
    };

    return {
        init : init
    };

})();

export { FormSelect };
