/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';

const TabbedContent = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {
        tabs : document.querySelectorAll( '.tabs li' ),
        tabsContent : document.querySelectorAll( '.tab-content' ),
        activeClass : 'is-active'
    },

    deactvateAllTabs = function() {
        config.tabs.forEach( function( tab ) {
            tab.classList.remove( config.activeClass );
        });
    },

    hideTabsContent = function() {
        config.tabsContent.forEach(function( tabContent ) {
            tabContent.classList.remove( config.activeClass );
        });
    },

    activateTabsContent = function( tab ) {
        config.tabsContent[ getIndex( tab ) ].classList.add( config.activeClass );
    },

    getIndex = function (el) {
        return [...el.parentElement.children].indexOf(el);
    },

    init = function() {

        if( config.tabs.length ) {

            config.tabs.forEach(function (tab) {
                tab.addEventListener(
                    'click',
                    function () {
                        deactvateAllTabs();
                        hideTabsContent();
                        tab.classList.add('is-active');
                        activateTabsContent(tab);
                    }
                );
            })

            config.tabs[0].click();

        }

    };

    return {
        init : init
    };

})();

export { TabbedContent };
