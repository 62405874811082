(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["articles.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "articles"))) {
output += "\n";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "articles");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("article", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n<div class=\"image-card is-light-card\">\n    ";
if(env.getFilter("length").call(context, runtime.memberLookup((t_4),"image"))) {
output += "\n    <div class=\"image-card-head\">\n        <div class=\"is-labels\"><span class=\"tag is-white is-uppercase\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"category"), env.opts.autoescape);
output += "</span></div>\n        <div class=\"is-img is-lazy-bg is-blur-img is-added-lazy-image\" data-img-src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"image"), env.opts.autoescape);
output += "\"><img src=\"/backgrounds/pixel.svg\" /></div>\n    </div>\n    ";
;
}
output += "\n    <div class=\"image-card-body\">\n        ";
if(!env.getFilter("length").call(context, runtime.memberLookup((t_4),"image"))) {
output += "<p class=\"section-label is-small\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"category"), env.opts.autoescape);
output += "</p>";
;
}
output += "\n        <p><a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "</a></p>\n        <div class=\"image-card-body-meta\">\n            <ul class=\"is-articles-meta-list\">\n                <li>\n                    <i class=\"far fa-calendar-alt\"></i>\n                    <span class=\"meta\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"date"), env.opts.autoescape);
output += "</span>\n                </li>\n                <li>\n                    <i class=\"fas fa-book-open\"></i>\n                    <span class=\"meta\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"readtime"), env.opts.autoescape);
output += "</span>\n                </li>\n                ";
if(env.getFilter("length").call(context, runtime.memberLookup((t_4),"tags"))) {
output += "\n                <li>\n                    ";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"tags");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("tag", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                    <span class=\"tag is-primary\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</span>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </li>\n                ";
;
}
output += "\n            </ul>\n        </div>\n        <p class=\"is-margin-top-1\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_4),"excerpt")), env.opts.autoescape);
output += "</p>\n        <p class=\"is-margin-top-1\">\n            <a class=\"button is-link is-regular is-outlined is-margin-right-1 is-scroll-to\" \n            href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "\">\n                Read more.\n            </a>\n        </p>\n    </div>\n</div>\n\n";
;
}
}
frame = frame.pop();
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

