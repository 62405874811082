/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';

const ScrollTo = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let doScroll = function() {
        $(".is-scroll-to").on('click', function(e) {
            e.preventDefault();
            doAnimatedScroll( $( this ).attr( 'href' ) );
            $(this).blur();
        });
        $(".is-hero-scroll").on('click', function(e) {
            e.preventDefault();
            doHeroAnimatedScroll();
            $(this).blur();
        });
    },

    doHeroAnimatedScroll = function() {
        let currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let reqScrollTop = currentScrollTop + window.innerHeight;
        $('html, body').animate({ scrollTop: reqScrollTop - 78 }, 500, 'linear');
    },

    doAnimatedScroll = function( elemId ) {
        $('html, body').animate({ scrollTop: $( elemId ).offset().top - 78 }, 500, 'linear');
    },

    doElementScroll = function( elemId ) {
        doAnimatedScroll( elemId );
    };

    return {
        doScroll : doScroll,
        doElementScroll : doElementScroll
    };

})();

export { ScrollTo };
