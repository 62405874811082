/*
 * Module: LazyLocationTrials
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import _, { forEach } from 'underscore';
import { Fetch } from '../../api/fetch';
import { EventManager } from '../../util/eventManager';
import { RequestUtil } from '../../util/requestUtil';
import { NunjucksEnv } from '../../util/nunjucksEnv';
import { Logger } from '../../util/logger';
import { trials } from '../../templates/trials';

const LazyLocationTrials = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = { 
        selectors : {
            locationTrials : 'location-trials-content'
        }
    },

    init = ( condition ) => {
        config.condition = condition;
        setListingObservers();
    },

    setListingObservers = () => {
        if( isSupportsIntersectionObserver() )
            initListingObservers();
    },

    isSupportsIntersectionObserver = () => {
        return ( "IntersectionObserver" in window );
    },

    initListingObservers = function() {

        Logger.log( 'initListingObservers' );
        
        // Get all lazy elements
        let lazyListings = document.querySelectorAll( '.' + config.selectors.locationTrials );

        // Observe
        observeAll( lazyListings );

        // Event listeners
        setTrialsEventListeners( lazyListings );

    },

    observeAll = ( lazyListings ) => {
        let observer = new IntersectionObserver(
            (entries, observer) => { 
                entries.forEach(entry => {
                    if ( entry.isIntersecting ) {
                        let listing = entry.target,
                            dataset = listing.dataset;
                        observer.unobserve( listing );   
                        getLocationTrials( dataset );
                        Logger.log( 'Render trials for ' + dataset.locationId );
                    } 
                }); 
            }
        );
        unobserveAll( observer );
        lazyListings.forEach( function( listing ) {
            observer.observe( listing );
        });
    },

    unobserveAll = ( listingObserver ) => {
        listingObserver.disconnect();
    },

    setTrialsEventListeners = function( lazyListings ) {
        lazyListings.forEach(element => {
            let eventKey = 'trials.listings-by-location-' + element.dataset.locationId;
            EventManager.off( eventKey );
            EventManager.on(     
                eventKey,
                function( d ) {          
                    
                    Logger.log( 'Render trials', d );

                    let trialData = getTrialData( d.trials ),
                    data = { trials : trialData },
                    html = false;    

                    try {
                        html = NunjucksEnv.get().render(
                            'trials.njk',
                            data
                        );
                    } catch(err) { }

                    let trialsEl = document.getElementById( 'trials-' + d.locationId );
                    if( html ) {
                        trialsEl.innerHTML = html;
                    }

                    // Unsubscribe event
                    EventManager.off( eventKey );

                    // Remove class 
                    trialsEl.classList.remove( config.selectors.locationTrials );

                }
            );
        });
    },

    getLocationTrials = ( dataset ) => {

        let locId = dataset.locationId,
            eventKey = 'trials.listings-by-location-' + locId;
        Logger.log( 'getLocationTrials for ' + locId );

        let url = RequestUtil.getHost() + '/ctrials/location/trials-by-loc?locationId=' + locId;
        if( config.condition ) {
            url += '&condition=' + config.condition;
        }

        Fetch.doFetch(
            url,
            { method : 'get' },
            eventKey
        );     

    },

    getTrialData = ( trials ) => {
        return _.map(
            trials,
            function( item, key, list ) {
                let trialsCount = list.length,
                    isOddNumTrials = trialsCount%2,
                    className = trialsCount < 2 || ( isOddNumTrials && ((key+1) === trialsCount)) ? 'is-12' : 'is-half';
                item.cardClass = className;
                return item;
            }
        );
    };

    return {
        init : init
    };

})();

export { LazyLocationTrials };