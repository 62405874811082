/*
 * Module: GTM
 *
 * @author Neil Palmer / UI Farm
 */
import { Logger } from '../util/logger';
import { Fetch } from '../api/fetch';
import { RequestUtil } from '../util/requestUtil';
import { EventManager } from '../util/eventManager';

const GTM = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {
        containerID : false
    },

    init = function() {

        Logger.log( 'Setup Google Tag Mgr...' );

        // Fetch all conditions from db
        Fetch.doFetch(
            RequestUtil.getHost() + '/api/options/analytics_theme_display_options?api_token=Jll7q0BSijLOrzaOSm5Dr5hW9cJRZAJKOzvDlxjKCXepwAeZ7JR6YP5zQqnw',
            {
                method: 'get',
                headers: {
                    'Content-Type' : 'application/json'
                }
            },
            'tracking.settings'
        );

        // Init Typeahead for conditions
        EventManager.on(
            'tracking.settings',
            function( d ) {
                if( d.option_value.gtm_tracking_prop_id ) {
                    config.containerID = d.option_value.gtm_tracking_prop_id;
                    doGTM( config.containerID );
                }
            }
        );

    },

    doGTM = function( containerID ) {
        Logger.log( 'Do GTM', config.containerID );
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',containerID);
    };

    return {
        init : init
    };

})();

export { GTM };
