(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["listings.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"columns is-multiline is-desktop\">\n\n    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "trials"))) {
output += "\n    ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "trials");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("trial", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\n    <div class=\"image-card is-light-card ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"cardClass"), env.opts.autoescape);
output += "\">\n        <div class=\"image-card-body\">\n            <p><a class=\"card-link\" href=\"/clinical-trials/trial/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"nct_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "</a></p>\n            <p class=\"image-card-body-meta\"><i class=\"far fa-calendar-alt\"></i><span class=\"meta\">Starting ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"start_date"), env.opts.autoescape);
output += "</span><i class=\"fas fa-check-square\"></i><span class=\"meta\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"status"), env.opts.autoescape);
output += "</span></p>\n            <p class=\"content-excerpt\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"summary"), env.opts.autoescape);
output += "</p>    \n            <p class=\"is-margin-top-1\"><a class=\"button is-link is-regular  is-outlined is-margin-right-1 is-scroll-to\" href=\"/clinical-trials/trial/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"nct_id"), env.opts.autoescape);
output += "\" style=\"font-size:1rem;\">View trial.</a></p>    \n        </div>\n        <div class=\"image-card-foot\">\n            ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "conditions"))) {
output += "\n            ";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "condition");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("conditions", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n            <span class=\"tag\" style=\"margin-right:.5rem\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "condition")),"name"), env.opts.autoescape);
output += "</span>\n            ";
;
}
}
frame = frame.pop();
output += "\n            ";
;
}
output += "\n        </div>\n    </div>\n\n    ";
;
}
}
frame = frame.pop();
output += "\n    ";
;
}
output += "\n\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

