import { Bootstrap } from './bootstrap.js';
import { DOMElements as DOMHelper } from './dom/domElements';

/**
 * By UI Farm Ltd, London
 */

(function() {

    document.addEventListener(
        'DOMContentLoaded',
        () => {

            // Start App
            Bootstrap.init({
                main : !DOMHelper.isPresent( 'body.mc-leadgen' )
            });
               
        }
    );

})();
