/*
 * Module: Animation
 *
 * @author UI Farm
 */

const AnimationEvents = (function() {
	
	var options = {
		transitionEndEvents : {
			"transition"      : "transitionend",
			"OTransition"     : "oTransitionEnd",
			"MozTransition"   : "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		},
		animationEndEvents : {
			"animation"      : "animationend",
			"OAnimation"     : "oAnimationEnd",
			"MozAnimation"   : "animationend",
			"WebkitAnimation": "webkitAnimationEnd"
		}
	},
	
	getAnimationEndEvent = function() {
		return getEndEvent( options.animationEndEvents );
	},
	
	getTransitionEndEvent = function() {
		return getEndEvent( options.transitionEndEvents );
	}

	var getEndEvent = function( endEventMap ) {
		
		var t,
			el = document.createElement( 'testEl' );
		
		for ( t in endEventMap ){
			if ( el.style[ t ] !== undefined ){
				return endEventMap[ t ];
			}
		}
		
	};

    return {
        getAnimationEndEvent : getAnimationEndEvent,
		getTransitionEndEvent : getTransitionEndEvent
    };

})();

export { AnimationEvents };