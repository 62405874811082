/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import DetectRTC from 'detectrtc';

const TwilioCall = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');
    const Device = require('twilio-client').Device;

    let options = {
        el : {
            callStatus : $( '#call-status' ),
            callButton : $( '#call-button' ),
            callButtonLabel : $( '#call-button-label' ),
            callStatusMsg : $( '#call-status-message' )
        },
        messages : {
            unsupported : 'Sorry, your browser does not support calls.',
            connecting : 'Connecting...',
            connected : 'Connected',
            ready : 'Ready'
        }
    };

    let init = function() {

        $.get( "/twilio/token", function(data) {
            try {
                Device.setup( data.token );
            } catch(err) {

                //if( err.indexOf( 'WebRTC' ) !== -1 ) {

                    $( '#section-lead-call' ).hide();
                    $( '#section-webrtc' ).removeClass( 'is-hidden');

                //}

            }
        });

        /* Callback to let us know Twilio Client is ready */
        Device.ready(function (device) {
            setupCallHandler();
            updateCallStatus( options.messages.ready );
        });

    },

    doShowCallStatus = function() {
        options.el.callStatus.removeClass( 'is-hidden' );
    },

    setupCallHandler = function() {

        // Show status
        doShowCallStatus();

        // Call handler
        doCallHander();

        Device.on('connect', function (connection) {

            updateCallStatus( options.messages.connected );

            // Update button UI
            options.el.callButtonLabel.text( 'Hang up.' );
            options.el.callButton.removeClass( 'is-success' ).addClass( 'is-link' );

            // Reset handler for hang up
            doHangUpHandler();

        });

        Device.on('disconnect', function(connection) {
            updateCallStatus( 'Call ended' );
            options.el.callButtonLabel.text( 'Call.' );
            options.el.callButton.removeClass( 'is-link' ).addClass( 'is-success' );
            doCallHander();
        });

    },

    doCallHander = function() {
        options.el.callButton.off( 'click.call' );
        options.el.callButton.on(
            'click.call',
            function( e ) {
                e.preventDefault();
                let params = { 'client' : $( this ).data( 'client' ) };
                updateCallStatus( options.messages.connecting );
                Device.connect( params );
            }
        );
    },

    doHangUpHandler = function() {
        options.el.callButton.off( 'click.call' );
        options.el.callButton.on(
            'click.call',
            function( e ) {
                e.preventDefault();
                Device.disconnectAll();
            }
        );
    },

    updateCallStatus = function( msg ) {
        options.el.callStatusMsg.text( msg );
    };

    return {
        init : init
    };

})();

export { TwilioCall };
