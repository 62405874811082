/*
 * Module: ListingsFilter
 *
 * @author Neil Palmer / UI Farm
 */

import _ from 'underscore';
import $ from 'jquery';
import { EventManager } from '../../util/eventManager';

const ListingsFilter = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = {
        selectors : {
            openFilters : 'btn-filter',
            closeFilters  :'btn-modal-close',
            filters : 'modal-filters',
            form : 'form-filter',
            applyBtn : 'btn-apply'
        },
        filterStatus : false,
        filterStorageKey : 'mc360_settings'
    },

    init = () => {
        doModalTransitionListener();
        setOpenFilterEventHandler();
        setCloseFilterEventHandler();
        setApplyFiltersEventHandler();
        initFilterValues();
        showFilterButton();
    },

    doModalTransitionListener = () => {
        EventManager.on( 'modal.status', function() {
            if( !config.filterStatus ) {
                let filtersModal = document.getElementById( config.selectors.filters );
                filtersModal.classList.remove( 'is-modal-visible' );
                filtersModal.classList.add( 'is-modal-hidden' );
            }
        }); 
    },

    doRadiusRange = function() {
        $('[type="range"]').on('change input', function() {
            let val = $(this).val();
            $( '#range_miles' ).text( val );
            $( '#mile_plural' ).text( val === '1' ? '' : 's' );
        });
    },

    showFilterButton = () => {
        document.getElementById( config.selectors.openFilters ).classList.remove( 'is-hidden' );
        document.getElementById( 'modal-filters' ).classList.remove( 'is-modal-hidden' );
    },

    setOpenFilterEventHandler = () => {
        let button = document.getElementById( config.selectors.openFilters );
        button.addEventListener( "click", ( e ) => {
            if( !config.filterStatus ) {
                config.filterStatus = true;
                e.preventDefault;
                openFilters();
            }
        });
    },

    setApplyFiltersEventHandler = () => {

        let btn = document.getElementById( config.selectors.applyBtn );
        btn.addEventListener( "click", ( e ) => { 
            if( e.detail < 2 ) {
                e.preventDefault;
                applyForm();
                config.filterStatus = false;
                return false;
            }
        });

    },

    setCloseFilterEventHandler = () => {

        let button = document.getElementById( config.selectors.closeFilters );
        button.addEventListener( "click", ( e ) => {
            if( config.filterStatus ) {
                config.filterStatus = false;
                e.preventDefault;
                closeFilters();
                resetForm();
            }
        });

    },

    openFilters = () => {
        let filtersModal = document.getElementById( config.selectors.filters );
        filtersModal.classList.remove( 'is-modal-hidden' );
        filtersModal.classList.add( 'is-modal-open' );
        document.getElementsByTagName( 'html' )[0].classList.add( 'is-clipped' );
    },

    closeFilters = () => {
        let filtersModal = document.getElementById( config.selectors.filters );
        filtersModal.classList.remove( 'is-modal-open' );
        document.getElementsByTagName( 'html' )[0].classList.remove( 'is-clipped' );
    },
    
    resetForm = () => {
        let formEl = document.getElementById( config.selectors.form );
        if( formEl ) formEl.reset();
    },

    applyForm = () => {

        // Build and save form filter values
        let filterValues = {
            radius : document.getElementById( 'filter-radius' ).value
        };

        // Add condition
        let conditionEl = document.getElementById( 'filter-condition' );
        if( conditionEl && '' !== conditionEl.options[conditionEl.selectedIndex].value ) {
            let condtionVals = conditionEl.options[conditionEl.selectedIndex].value.split('-');
            filterValues.conditionId = condtionVals[ 0 ];
            filterValues.conditionName = condtionVals[ 1 ];
        }

        // Save to local storage
        saveToLocalSessionStorage( filterValues );

        // Fire event
        EventManager.emit( 'listings.listings-filter', filterValues );

        // Close form
        closeFilters();

    },

    saveToLocalSessionStorage = ( obj ) => {
        if ( typeof( Storage ) !== "undefined" ) {
            sessionStorage.setItem(
                config.filterStorageKey,
                JSON.stringify( obj )
            );
        }
        return true;
    },

    deleteLocalSessionVal = () => {
        if ( typeof( Storage ) !== "undefined" ) {
            sessionStorage.removeItem( config.filterStorageKey );
        }
    },

    getLocalSessionVal = () => {
        if ( typeof( Storage ) !== "undefined" ) {
            let savedSettings = sessionStorage.getItem( config.filterStorageKey );
            if( savedSettings ) {
                return JSON.parse( savedSettings );
            }
        }
        return false;
    },

    initFilterValues = () => {

        // Get saved filter values from local session storage
        let savedFilterValues = getLocalSessionVal();
        
        // Condition 
        if( savedFilterValues ) {
            if( savedFilterValues.conditionId && savedFilterValues.conditionName ) {
                let conditionValue = savedFilterValues.conditionId + '-' + savedFilterValues.conditionName;
                document.getElementById( 'filter-condition' ).value = conditionValue;
            }
        }

        // Radius
        let radius = savedFilterValues && savedFilterValues.radius ? savedFilterValues.radius : 50;
        document.getElementById( 'filter-radius' ).value = radius;
        document.getElementById( 'range_miles' ).innerText = radius;
        doRadiusRange();

    };

    return {
        init : init,
        getSavedFilters : getLocalSessionVal,
        doDeleteFilters : deleteLocalSessionVal
    };

})();

export { ListingsFilter };
