/*
 * Module: Logger
 *
 * @author Neil Palmer / UI Farm
 */

const Logger = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let log = function( msg, obj, level ) {
        level = level || 'log';
        if( APP_CONFIG.debug ) {
            if( obj ) {
                console[ level ]( msg, obj );
            } else {
                console[ level ]( msg );
            }
        }
    };

    return {
        log : log
    };

})();

export { Logger };
