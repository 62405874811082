/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */

import $ from 'jquery';
import IntersectionObserverAdmin from 'intersection-observer-admin';

const LazyImages = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    const intersectionObserverAdmin = new IntersectionObserverAdmin();

    let doLazyBackgroundImages = function( imgQuerySelector) {

        var lazyloadImages;

        if ("IntersectionObserver" in window) {

            imgQuerySelector = imgQuerySelector || '.is-lazy-bg';
            lazyloadImages = document.querySelectorAll( imgQuerySelector );

            var imageObserver = new IntersectionObserver(function(entries, observer) {
              entries.forEach(function(entry) {
                //let entry = entries[0];
                if (entry.isIntersecting) {    

                  var image = entry.target,
                    imgSrc = image.dataset.imgSrc;

                    if( imgSrc ) {
                        var tempImage = new Image();
                        tempImage.onload = function() {
                            image.style.backgroundImage = 'url(' + imgSrc + ')';
                            image.classList.remove("is-lazy-bg");
                            image.classList.add("is-no-blur");
                            imageObserver.unobserve(image);
                        };
                        tempImage.src = imgSrc;
                    } else {
                        image.classList.remove("is-lazy-bg");
                        image.classList.add("is-no-blur");
                        imageObserver.unobserve(image);
                    }



                }
              });
            });

            /*
            lazyloadImages.forEach(function(image) {
              imageObserver.observe(image);
            });*/

            //imageObserver.observe(lazyloadImages);
            lazyloadImages.forEach(imageObserver.observe.bind(imageObserver));

        } else {

            // Turn lazy off
            $( '.is-lazy-bg' ).removeClass( 'is-lazy-bg' );

        }

    },

    addLazyImages = () => {
      doLazyBackgroundImages( '.is-added-lazy-image' );
    };

    return {
        doLazyBackgroundImages : doLazyBackgroundImages,
        addLazyImages : addLazyImages
    };

})();

export { LazyImages };
