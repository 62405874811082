/*
 * Module: Navbar
 *
 * @author Neil Palmer / UI Farm
 */

import { Logger } from '../../util/logger';
import { DOMElements as DOMHelper } from '../../dom/domElements';

const Navbar = ( function() {

    let config = {
        elements : {
            burger : DOMHelper.get( '.navbar-burger' ),
            menu : DOMHelper.get( '.navbar-menu' )
        },
        navClass : 'is-active',
        namespacedClick : 'click'
    },

    init = function() {

        if( config.elements.menu && config.elements.burger ) {

            Logger.log( 'init navbar...' );

            // Check for click events on the navbar burger icon
            config.elements.burger.addEventListener(
                config.namespacedClick,
                function( e ) {
                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    config.elements.burger.classList.toggle( config.navClass );
                    config.elements.menu.classList.toggle( config.navClass );
                }
            );

        }

    };

    return {
        init : init
    };

})();

export { Navbar };
