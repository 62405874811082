/*
 * Module: TrialListings
 *
 * @author Neil Palmer / UI Farm
 */

import _ from 'underscore';
import { Fetch } from '../../api/fetch';
import { EventManager } from '../../util/eventManager';
import { RequestUtil } from '../../util/requestUtil';
import { NunjucksEnv } from '../../util/nunjucksEnv';
import { LazyLocationTrials } from './lazy-location-trials';
import { Logger } from '../../util/logger';
import { ListingsFilter } from './listings-filter';
import { notrials } from '../../templates/no-trials';
import { listinglocation } from '../../templates/listing-location'; 

const TrialListings = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = {
        trialListingsEl : false,
        pagination : {
            offset : 0,
            perPage : 10,
            total : 0,
            currentCount : 0
        },
        loadMoreInOp : false,
        elements : [],
        filtersLoaded : false,
        condition : false,
        radius : 50
    },

    resetPagination = () => {
        config.pagination = {
            offset : 0,
            perPage : 10,
            total : 0,
            currentCount : 0
        };
    },

    doFiltersOnInit = () => {
        
        // Get last page from session
        let lastPagePath = sessionStorage.getItem( 'm360_current_page' );

        // Get current page
        let currentPagePath = RequestUtil.getCurrentPath();

        // Do filter if current page has been refreshed or user has come from trial page
        if( currentPagePath === lastPagePath || 
            ( lastPagePath && lastPagePath.indexOf( '/clinical-trials/trial/NCT' ) !== -1 ) ) {
            return true;
        }

        return false;

    },

    doApplyFiltersOnInit = () => {
        if( doFiltersOnInit() ) {
            // Get filters from session
            let savedFilters = ListingsFilter.getSavedFilters();
            // Apply filters if found
            if( savedFilters ) 
                setSearchParameters( savedFilters );
        } else {
            // remove saved filters
            ListingsFilter.doDeleteFilters();
        }
    },

    init = () => {

        // Get listings el
        let el = isShowTrialListings();

        // Save Data
        saveData( el );

        // Show listings
        if( el ) {

            // Filters
            doApplyFiltersOnInit();

            // Setup
            setListingsEventListener();

            // Get listings
            getListings();

            // Load more CTA
            setLoadMoreEventHandler();

        }
        
    },

    setFilterChangeEventListener = () => {
        EventManager.on(
            'listings.listings-filter',
            ( d ) => {
                // Reset UI
                doListingsUILoading();

                // Set parameters
                setSearchParameters( d );

                // Reset pagination vars
                resetPagination();

                // Get listings
                getListings();

            }
        )
    },

    doListingsUILoading = () => {

        // Remove exisitng content
        document.getElementById( 'trial-listings-content' ).innerHTML = '';

        // TODO: add loading anim / html placeholder
        document.getElementById( 'trial-listings-content-ph' ).classList.remove( 'is-hidden' );

    },

    setSearchParameters = ( searchOptions ) => { 

        Logger.log( 'setSearchParameters.searchOptions', searchOptions );
        
        // Set condition
        let el = isShowTrialListings();
        if( el && el.getAttribute( 'data-condition' ) ) {
            config.condition = el.getAttribute( 'data-condition' );
        } else {
            config.condition = searchOptions.conditionId ? searchOptions.conditionId : false;
        }

        // Set Radius
        config.radius = searchOptions.radius ? parseInt( searchOptions.radius ) : 50;

    },

    setLoadMoreEventHandler = () => {
        
        let loadMoreCtaEl = document.getElementById( 'btn-trial-listings' );
        config.elements.loadMore = loadMoreCtaEl;

        loadMoreCtaEl.addEventListener( "click", function( e ) {
            if( !config.loadMoreInOp ) {
                config.loadMoreInOp = true;
                e.preventDefault;
                loadMoreCtaEl.classList.add( 'is-loading' );
                getListings();
            }
        });

    },

    saveData = function( el ) {
        if( el ) {
            config.trialListingsEl = el;    
            config.longitude = el.getAttribute( 'data-lng' );
            config.latitude = el.getAttribute( 'data-lat' );
            config.condition = el.getAttribute( 'data-condition' ) ? el.getAttribute( 'data-condition' ) : false;
            Logger.log( 'Config', config );
        }
    },

    setListingsEventListener = () => {
        EventManager.on(
            'trials.listings-by-location',
            function(d) {

                Logger.log( 'Locations callback...', d );

                // Add total
                saveTotalLocationsCount( d );

                // Add/render location listings
                doRenderListings( d );

                // Setup lazy trial listings
                LazyLocationTrials.init( config.condition );

                // Pagination
                doPagination( d );

                // Remove event handler
                //EventManager.off( 'trials.listings-by-location' );
                config.loadMoreInOp = false;
                config.elements.loadMore.classList.remove( 'is-loading' );

            }
        );
    },

    doPagination = ( d ) => {
        
        // Set current total count
        config.pagination.currentCount =  config.pagination.currentCount + d.listings.length;

        // Are there more?
        if( config.pagination.currentCount < config.pagination.total ) {

            // increase offset
            config.pagination.offset = config.pagination.offset + config.pagination.perPage;

            Logger.log( 'More results left to load', config.pagination );

            let loadMoreCtaEl = document.getElementById( 'btn-trial-listings' );
            if( loadMoreCtaEl ) loadMoreCtaEl.classList.remove( 'is-hidden' );

        } else {

            // Hide 'Load More' CTA
            let loadMoreCtaEl = document.getElementById( 'btn-trial-listings' );
            if( loadMoreCtaEl ) loadMoreCtaEl.classList.add( 'is-hidden' );

        }

    },

    getListingsURL = () => {
        let listingsURL = 
            RequestUtil.getHost() + 
            '/ctrials/location/trials?lng=' + 
            config.longitude + 
            '&lat=' + 
            config.latitude +
            '&offset=' +
            config.pagination.offset;

        if( config.condition ) {
            listingsURL += '&condition=' + config.condition;
        }

        if( config.radius ) {
            listingsURL += '&radius=' + config.radius;
        }

        return listingsURL;

    },

    getListings = () => {
        Fetch.doFetch(
            getListingsURL(),
            { method : 'get' },
            'trials.listings-by-location'
        );
    },

    saveTotalLocationsCount = function( d ) {
        if( config.pagination.total === 0 ) {
            config.pagination.total = d.listingsTotal;
        }
        Logger.log( 'Pagination', config.pagination );
    },

    isNoListings = () => config.pagination.total === 0,

    doRenderListings = function( d ) { 

        // Hide loading anim
        document.getElementById( 'trial-listings-content-ph' ).classList.add( 'is-hidden' );
        
        if( isNoListings() ) {

            let html = NunjucksEnv.get().render( 'no-trials.njk' );
            document.getElementById( 'trial-listings-content' ).innerHTML = html;

        } else {
        
            let listings = getMappedListings( d.listings ),
                html = '';

            listings.forEach(element => {
                html += NunjucksEnv.get().render(
                    'listing-location.njk',
                    element
                );
            });  

            let listingsContentEl = document.getElementById( 'trial-listings-content' );
            listingsContentEl.innerHTML = listingsContentEl.innerHTML + html;

            // Reset submit button
            config.elements.loadMore.blur();

        }

        // Init filters
        if( !config.filtersLoaded ) {
            ListingsFilter.init();
            setFilterChangeEventListener();
            config.filtersLoaded = true;
        }

    },

    getMappedListings = function( listings ) {
        return listings.map( addDataToListing );
    },

    addDataToListing = function( listing ) {
        listing.address = listing.city + ', ' + listing.state + ' ' + listing.zipcode;
        listing.isLessOneMile = listing.distance < 1 ? true : false;
        return listing;
    },

    isShowTrialListings = () => {
        return document.querySelector( 'section[data-module="trial-listings"]' );
    };

    return {
        init : init
    };

})();

export { TrialListings };
