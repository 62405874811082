/*
 * Module: LeadForm
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import { Logger } from '../util/logger';

const LeadForm = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {
        elements : {
            form : $( '.frm_pro_form ' )
        },
        tracking : []
    },

    saveTrackingValues = () => {

        // Save client
        saveC6ClientId();

        // Save country code
        saveUserCountryCode();

        // Save UTM params
        saveUTMTracking();

        // Save landing page 
        saveLandingPage();

        Logger.log( 'Tracking', config.tracking );

    },

    saveC6ClientId = () => {
        config.tracking.client_id = $( 'body' ).data( 'client-id' );
    },

    setC6Client = function() {
        addHiddenInput( 'client_id', $( 'body' ).data( 'client-id' ) );
    },

    setUserCountryCode = function() {
        addHiddenInput( 'user_country_code', $( 'body' ).data( 'country-code' ) );
    },

    saveUserCountryCode = () => {
        config.tracking.user_country_code = $( 'body' ).data( 'country-code' );
    },

    addHiddenInput = function( name, val ) {
        if( $( '#' + name ).length === 0 ) {
            Logger.log( 'Adding field', [name, val] );
            let form = $( '.frm_pro_form' ),
                fieldsContainer = form.find( '.frm_fields_container' ).eq( 0 );
            fieldsContainer.prepend(
                $( '<input/>', {
                    type : 'hidden',
                    name : name,
                    value : val,
                    id : name
                })
            );
        }
    },

    setUTMTracking = function() {
        let utmParams = [ 'medium', 'source', 'campaign', 'term' ];
        for ( let i=0; i<utmParams.length; i++ ) {
            let name = 'utm_' + utmParams[ i ];
            addHiddenInput( name, getRequestParameterValue( name ) );
        }
    },

    saveUTMTracking = () => {
        let utmParams = [ 'medium', 'source', 'campaign', 'term' ];
        for ( let i=0; i<utmParams.length; i++ ) {
            let name = 'utm_' + utmParams[ i ];
            config.tracking[ name ] = getRequestParameterValue( name );
        }
    },

    isForm = function() {
        return $( '.frm_pro_form' ).length;
    },

    getRequestParameterValue = function( name ) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },

    getLandingPage = () => {
        let landingPage = window.location.pathname;
        landingPage = landingPage.length > 100 ? landingPage.substr( 0,97 ) + '...' : landingPage;
        return landingPage;
    },

    setLandingPage = function() {
        addHiddenInput( 'landing_page', getLandingPage() );
    },

    saveLandingPage = function() {
        config.tracking.landing_page = getLandingPage();
    },

    addTrackingHiddenFields = () => {
        for( const[ inputName, inputValue ] of Object.entries( config.tracking ) ) {
            addHiddenInput( inputName, inputValue );
        }
    },

    doSubmitForm = () => {
        // Add tracking data to the form as hidden inputs
        $( '.frm_pro_form' ).on(
            'submit',
            function(e) {
                // Add hidden fieldsfor tracking data
                addTrackingHiddenFields();
            }
        );
    },

    doInitForm = () => {
        addTrackingHiddenFields();
    },

    doRenderForm = () => {
        // Remove HTML elements
        $( 'input[type="text"],input[type="email"],input[type="tel"],input[type="number"],.field_indication' ).each(function(i,v) {
            $( v ).prev().hide();
        });

        // Show form
        $( '#mc-form-content').removeClass( 'is-hidden' );
    },

    doAddTrackingInputs = () => {
        
        // Save tracking values
        saveTrackingValues();

        // Submit form with tracking data
        doAddTrackingDataHiddenInputs();

    },

    doAddTrackingDataHiddenInputs = () => {
        addTrackingHiddenFields();
        setTimeout( doAddTrackingDataHiddenInputs, 1000 );
    },

    init = function() {
        if( isForm() ) {

            // Render form
            doRenderForm();

            // Add tracking data to form
            doAddTrackingInputs();

        }
    };

    return {
        init : init
    };

})();

export { LeadForm };
