/*
 * Module: DOMElements
 *
 * @author Neil Palmer / UI Farm
 */
const DOMElements = ( function() {

    /**
     * isPresent
     * - return true if the element by id exists in the DOM
     */
    let isPresent = ( selector ) => !!get( selector ),

    /**
     * appendToElement
     * - append HTML content to an elements existing HTML
     */
    appendTo = ( element, html ) => {
        if( element ) {
            let appendedHtml = element.innerHTML + html;
            element.innerHTML = appendedHtml;
        }
    },

    /**
     * get
     * - returns a single dom element
     */
    get = ( selector ) => {
        if( selector.startsWith( '#' ) ) {
            return document.getElementById( selector.substring( 1 ) );
        } else if( selector.startsWith( '.' ) ) {
            let elements = document.getElementsByClassName( selector.substring( 1 ) );
            if( elements && elements.length > 0 ) {
                return elements[0];
            }
        } else {
            let element = document.querySelector( selector );
            if( element ) return element;
        }
        return false;
    };

    return {
        isPresent : isPresent,
        appendTo : appendTo,
        get : get
    };

})();

export { DOMElements };