/*
 * Module: NunjucksEnv
 *
 * @author Neil Palmer / UI Farm
 */

import _ from 'underscore';
import nunjucks from 'nunjucks';

const NunjucksEnv = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {};

    let getNunjucks = function() {
        if( !config.nunjucks ) setNunjucks();
        return config.nunjucks;
    },

    setNunjucks = function() {
        
        let env = new nunjucks.Environment();

        env.addFilter(
            'formatCount',
            function(str) {
                return (str).toLocaleString( 'en-GB' );
            }
        );
        env.addFilter(
            'readTime',
            function(str) {
                var strippedString = str.replace(/(<([^>]+)>)/ig,"");
                var wordCount = strippedString.split(" ").length;
                var readingtime = Math.ceil( wordCount / 200 );
                return readingtime + ' minute read';
            }
        );
        env.addFilter(
            'displayDate',
            function( str ) {
                let format = 'D MMM YYYY';
                let date = moment( str );
                return date.format( format );
            }
        );
        env.addFilter(
            'excerpt',
            function( str ) {
                var strippedString = str.replace(/(<([^>]+)>)/ig,"");
                var resultArray = strippedString.split( ' ' );
                if( resultArray.length > 30 ) {
                    resultArray = resultArray.slice(0, 30);
                    return resultArray.join(" ") + "...";
                }
                return resultArray;
            }
        );
        config.nunjucks = env;
    };

    return {
        get : getNunjucks
    };

})();

export { NunjucksEnv };
