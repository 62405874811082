/*
 * Module: Fetch
 *
 * @author Neil Palmer / UI Farm
 */
import bluebird from 'bluebird';
import json3 from 'json3';
import fetchpolyfill2 from 'fetch-polyfill2';
import { EventManager } from '../util/eventManager';
import 'regenerator-runtime/runtime';
import { Logger } from '../util/logger';

const Fetch = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let doFetch = function( url, options, evt ) {

        return postData(url, options )
            .then(data => {
                EventManager.emit( evt, data );
            }).catch(error => {
                // When fetch ends with a bad HTTP status, e.g. 404
                Logger.log( 'Error', error.message );
            });

    },

    getOptions = ( overrideOptions ) => {

        let options = {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        };

        return Object.assign( options, overrideOptions );

    },

    postData = async function (url = '', options = {}) {

        let fetchOptions = getOptions( options );
        const response = await fetch( url, fetchOptions );
        const data = await response.json();
        return data; 

    };

    return {
        doFetch : doFetch
    };

})();

export { Fetch };
