/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import _ from 'underscore';
import validator from 'validator';
import { EventManager } from '../../util/eventManager';
import { Fetch } from '../../api/fetch';
import { RequestUtil } from '../../util/requestUtil';
import { Logger } from '../../util/logger';
import Suggestions from 'suggestions';
import { ScrollTo } from '../scrollTo';
import select2 from 'select2';

const CTSearchWidget = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = {
        form : $( '#form-ct-search' ),
        pageHeaderTitle : $( '#is-page-title' ),
        conditionPage : $( '.is-condition-pg' )
    },

    init = function() {

        // Test get config
        if( isWidgetPresent() ) {

            // Setup form submit
            setFormSubmit();

            // Prepopulate forms
            setFormValuesInit();

            // Scroll to message
            scrollToResultsNot();

            // Conditions search lookup
            //doConditionsLookup();
            doConditions();

            // Radius setting
            doRadiusRange();

        }

    },

    doConditions = function() {

        $( '#btn-dropdown-conditions' ).on(
            'click.conditions',
            function(e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).parents( '.dropdown' ).toggleClass( 'is-active' );
            }
        );

        $( '#dropdown-conditions .dropdown-item' ).on(
            'click.conditions-item',
            function(e) {
                e.preventDefault();
                let self = $( this );
                $( '#input-condition' ).val( self.data( 'condition-id' ) );
                self.parents( '.dropdown' ).removeClass( 'is-active' );
                $( '#btn-dropdown-conditions ').find( 'span' ).eq( 0 ).text( self.text() );
                $( '.dropdown button > .dropdown-menu-label' ).removeClass( 'is-placeholder' );

                // Highlighting current selection
                $( '#dropdown-conditions .dropdown-item' ).removeClass( 'is-active' );
                $( this ).addClass( 'is-active' );

                // Remove error class
                self.parents( '.dropdown' ).find( '.dropdown-button' ).removeClass( 'is-error' );

            }
        );
    
        document.addEventListener('click', function (event) {
            $('.dropdown').each(function(i,v) {
                if( $(v).hasClass( 'is-active' ) ) {
                    $(v).removeClass('is-active');
                }
            });
        });
        
      
        
          

    },

    scrollToResultsNot = function() {
        if( $( '#listingResultsNotification' ).length ) {
            _.delay( doScrollToResultsNot, 1000 );
        }
    },

    doScrollToResultsNot = function() {
        ScrollTo.doElementScroll( '#listingResultsNotification' );
    },

    initConditionsSuggestions = function() {

        // Fetch all conditions from db
        Fetch.doFetch(
            RequestUtil.getHost() + '/clinical-trials/conditions',
            {
                method: 'get',
                headers: {
                    'Content-Type' : 'application/json'
                }
            },
            'ct.allconditions'
        );

        // Init Typeahead for conditions
        EventManager.on(
            'ct.allconditions',
            function( d ) {
                var input = document.getElementById( 'suggestion_input' );
                var data = d;
                new Suggestions(input, data);
            }
        );

    },

    setFormSubmit = function() {
        config.form.on(
            'submit',
            function(e) {
                //e.preventDefault();
                let formStatus = validateForm();
                Logger.log( 'setFormSubmit:validation', formStatus );
                if( validateForm() === false ) {
                    return false;
                }
                config.form.find( 'button#button-find-trials' ).addClass( 'is-loading' );
                return true;
            }
        );
    },

    isWidgetPresent = function() {
        return ( config.form.length );
    },

    validateForm = function() {

        let validForm = true;

        // Remove any previous errors
        config.form.find( '> *' ).removeClass( 'is-danger is-error' );

        // Validate inputs
        config.form.find( 'input, select, button' ).each(
            function(i,v) {
                let input = $(this);

                if( isInputRequired( input ) ) {

                    if( isDropdownButton( input ) ) {
                        let dropdownHiddenVal = input.find( 'input' ).val();
                        if( dropdownHiddenVal === null || dropdownHiddenVal === '' ) {
                            input.addClass( 'is-error' ).focus();
                            validForm = false;
                        }
                    } else {
                        if( input.val() === null || input.val() === '' ) {
                            input.addClass( 'is-danger' ).focus();
                            validForm = false;
                        }
                    }

                }

                
            }
        );

        // Add focus on error
        if( !validForm ) config.form.find( 'input.is-danger' ).eq(0).focus();

        return validForm;

    },

    isDropdownButton =  ( el ) => {
        return el.hasClass( 'dropdown-button' );
    },

    isSuggestionField = function( input ) {
        let attr = input.data( 'suggestion-field' );
        if ( typeof attr !== typeof undefined && attr !== false && true === attr ) {
            return true;
        }
        return false;
    },

    isInputRequired = function( input ) {
        let attr = input.attr( 'required' );
        if ( typeof attr !== typeof undefined && attr !== false ) {
            return true;
        }
        return false;
    },

    getCsrfToken = function() {
        return $('meta[name="csrf-token"]').attr('content');
    },

    isPrePop = () => {
        return $( '#find-a-trial' ).data( 'prepopulate' );
    },

    setFormValuesInit = function() {
        
        if( config.conditionPage.length || isPrePop() ) {

            let findTrialEl = $( '#find-a-trial' );

            // Get cat/ind id
            let indicationId = findTrialEl.data( 'indication-id' );
            let buttonLabel = '';

            // Prepopulate form
            if( indicationId && '0' !== indicationId ) {

                // Set hidden field
                config.form.find( 'input[name="condition"]' ).val( indicationId );

                // Set current cat
                $( '#find-a-trial .dropdown-item' ).each(function( i, v ) {
                    let current = $( v );
                    if( current.data( 'condition-id' ) === indicationId ) {
                        current.addClass( 'is-active' );
                        buttonLabel = current.text();
                        return false;
                    }
                });

                // Set conditions button label
                $( '#btn-dropdown-conditions .dropdown-menu-label' )
                    .removeClass( 'is-placeholder')
                    .text( buttonLabel );

            }
        }
    },

    doConditionsLookup = function() {
        if( $( '#condition' ).length ) {

            // Test select2
            //Hook up select2 to jQuery
            select2( $ );
            // Init lookup select
            $( '#condition' ).select2({
                placeholder: 'Condition...',
                minimumInputLength: 2,
                ajax: {
                    url: '/clinical-trials/conditions',
                    dataType: 'json',
                    delay: 250,
                    processResults: function (data) {
                        return {
                            results: data
                        };
                    },
                    cache: true
                },
                language: {
                    inputTooShort: function(args) {
                        return "";
                    }
                },
                forceabove : false

            });

            $( '#condition' ).on(
                'select2:select',
                function(e) {
                    let input = $(this);
                    input.parent().find( '.select2 > .selection > .select2-selection' ).removeClass( 'is-danger' );
                    input.css( 'outline', 'none' );
                }
            );

            

        }
    },

    doRadiusRange = function() {
        $('[type="range"]').on('change input', function() {
            let val = $(this).val();
            $( '#range_miles' ).text( val );
            $( '#mile_plural' ).text( val === '1' ? '' : 's' );
        });
    };

    return {
        init : init
    };

})();

export { CTSearchWidget };
