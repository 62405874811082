/*
 * Module: Subscribe
 *
 * @author Neil Palmer / UI Farm
 */

import $ from 'jquery';

const CallButton = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let init = function() {

        let headerCopyElem = $( '.headerCopy > span' );
        if( headerCopyElem.length ) {
            const regex = /(\(?\d{3}\)?[ -.\s]\d{3}[ -.\s]\d{4})/gm;
            const str = headerCopyElem.text();
            const subst = '<a href="tel:$1">$1</a>';
            const result = str.replace(regex, subst);
            headerCopyElem.html( result );
        }

    };

    return {
        init : init
    };

})();

export { CallButton };
