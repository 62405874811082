/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import validator from 'validator';
import { EventManager } from '../../util/eventManager';
import { Fetch } from '../../api/fetch';
import { RequestUtil } from '../../util/requestUtil';
import { Logger } from '../../util/logger';

const Subscribe = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = {
        form : $( '.mc4wp-form' ),
        mailchimp : {
            listId : false,
            apiKey : false,
            messages : false
        },
        events : {
            mailchimp_config : 'evt.mailchimp.config',
            mailchimp_add_subscriber : 'evt.mailchimp.subscriber.add'
        }
    },

    doMailchimpConfig = function() {
        Fetch.doFetch(
            RequestUtil.getHost() + '/mailchimp/config',
            {
                method: 'post',
                body: JSON.stringify({
                    form_id : '',
                }),
                headers: {
                    'Content-Type' : 'application/json',
                    'X-CSRF-TOKEN' : getCsrfToken()
                }
            },
            config.events.mailchimp_config
        );
        EventManager.on(
            config.events.mailchimp_config,
            function( data ) {
                config.mailchimp.listId = data.mcListId;
                config.mailchimp.apiKey = data.apiKey;
                config.mailchimp.messages = data.messages;
                Logger.log( 'Mailchimp config', config.mailchimp );
            }
        );
    },

    getFormBody = function() {
        let body = {
            list_id : config.mailchimp.listId,
            api_key : config.mailchimp.apiKey,
            merge_fields : []
        };

        $( 'form.mc4wp-form input[type="text"],form.mc4wp-form input[type="email"]' ).each(
            function(i,v) {
                let input = $(this),
                    name = input.attr( 'name' ).toLowerCase();
                if( !name.startsWith( '_' ) ) {
                    body[ name ] = input.val();
                    body.merge_fields.push( name );
                }
            }
        );

        let data = [];
        $( 'form.mc4wp-form input[type="checkbox"], form.mc4wp-form input[type="radio"]' ).each(
            function( i, v ) {
                let input = $(this),
                    name = input.attr( 'name' );
                if( name ) {
                    name = name.toLowerCase();
                    if ( input.is( ":checked" ) ) {
                        let checkboxSet = data[ name ] || [];
                        checkboxSet.push( input.val() );
                        data[ name ] = checkboxSet;
                    }
                }

            }
        );

        let keys = Object.keys(data);
        for (var i = 0; i<keys.length; i++) {
            let inputName = keys[i],
                inputValue = data[ inputName ].join( ', ');
            body[ inputName ] = inputValue;
            body.merge_fields.push( inputName );
        }

        // Merge fields
        body.merge_fields = body.merge_fields.join(',');

        return body;

    },

    doListSubscription = function() {
        doMailchimpAddSubscriber();
        doListenMailchimpAddSubscriber();
    },

    doMailchimpAddSubscriber = function() {
        Fetch.doFetch(
            RequestUtil.getHost() + '/mailchimp/subscriber/add',
            {
                method: 'post',
                body: JSON.stringify( getFormBody() ),
                headers: {
                    'Content-Type' : 'application/json',
                    'X-CSRF-TOKEN' : getCsrfToken()
                }
            },
            config.events.mailchimp_add_subscriber
        );
    },

    doListenMailchimpAddSubscriber = function() {
        EventManager.off( config.events.mailchimp_add_subscriber );
        EventManager.on(
            config.events.mailchimp_add_subscriber,
            function( data ) {
                Logger.log( 'Mailchimp subscriber', data );

                // Remove any existing messages
                $( '.notification' ).remove();

                // Reset submit button
                config.form.find( 'button' ).removeClass( 'is-loading' );

                // Notifications
                if( data.status === 200 ) {

                    // Success - new subscription
                    if( data.response.status == 'subscribed' ) {
                        let message = $( '<div/>', {
                            text : config.mailchimp.messages.text_subscribed,
                            class : 'notification is-primary is-margin-bottom-2'
                        });
                        message.insertBefore( 'form.mc4wp-form' );
                        config.form.find( 'input[type="text"], input[type="email"], input[type="number"]' ).val( '' );
                        config.form.find( 'input[type="checkbox"], input[type="radio"]' ).prop( 'checked', false );
                    }
                    // Success - email already added
                    else if( data.response.status == 400 ) {
                        let message = $( '<div/>', {
                            text : config.mailchimp.messages.text_already_subscribed,
                            class : 'notification is-warning is-margin-bottom-2'
                        });
                        message.insertBefore( 'form.mc4wp-form' );
                    }
                    // Error
                    else {
                        let message = $( '<div/>', {
                            text : config.mailchimp.messages.text_error,
                            class : 'notification is-error is-margin-bottom-2'
                        });
                        message.insertBefore( 'form.mc4wp-form' );
                    }

                } else {

                    // Error
                    let message = $( '<div/>', {
                        text : config.mailchimp.messages.text_error,
                        class : 'notification is-error is-margin-bottom-2'
                    });
                    message.insertBefore( 'form.mc4wp-form' );

                }

            }
        );
    },

    init = function() {

        // Test get config
        if( isSubscribeFormPresent() ) {

            // Setup form
            doMailchimpConfig();

            // Setup form submit
            setFormSubmit();
            setFormInputKeyup();

        }

    },

    setFormSubmit = function() {
        config.form.find( 'button' ).on(
            'click.join',
            function(e) {
                e.preventDefault();
                let formStatus = validateForm();
                Logger.log( 'setFormSubmit:validation', formStatus );
                if( formStatus ) {

                    // Add loading animation
                    config.form.find( 'button' ).addClass( 'is-loading' );

                    // Do lead process
                    doListSubscription();

                }
                return false;
            }
        );
    },

    isSubscribeFormPresent = function() {
        return ( config.form.length );
    },

    validateForm = function() {
        let validForm = true;

        config.form.find( 'input' ).removeClass( 'is-danger' );
        $( 'form.mc4wp-form input[type="text"],form.mc4wp-form input[type="email"]' ).each(
            function(i,v) {
                let input = $(this);
                if( isInputRequired( input ) && input.val() === '' ) {
                    // Add error label
                    input.addClass( 'is-danger' );
                    validForm = setFormStatus( validForm, input );
                } else if( 'email' === input.attr( 'name' ).toLowerCase() && !validator.isEmail( input.val() ) ) {
                    input.addClass( 'is-danger' );
                    validForm = setFormStatus( validForm, input );
                } else {
                    input.removeClass( 'is-danger' )
                }
            }
        );

        return validForm;
    },

    setFormInputKeyup = function() {
        $( 'form.mc4wp-form input[type="text"],form.mc4wp-form input[type="email"]' ).on(
            'keyup',
            function(e) {
                let input = $( this );
                if( input.hasClass( 'is-danger' ) && input.val().length > 0 )
                    input.removeClass( 'is-danger' )
            }
        );
    },

    setFormStatus = function( status, input ) {
        if( status ) {
            input.focus();
            status = false;
        }
        return status;
    },

    isInputRequired = function( input ) {
        let attr = input.attr( 'required' );
        if ( typeof attr !== typeof undefined && attr !== false ) {
            return true;
        }
        return false;
    },

    getCsrfToken = function() {
        return $('meta[name="csrf-token"]').attr('content');
    };

    return {
        init : init
    };

})();

export { Subscribe };
