/*
 * Module: BMApp
 *
 * @author Neil Palmer / UI Farm
 */

import _ from 'underscore';
import $ from 'jquery';

const RequestUtil = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let getRequestParameter = function( parameterName ) {

        let query = window.location.search.substring( 1 ),
            vars = query.split( '&' );
        for ( let i=0; i<vars.length; i++ ) {
            var pair = vars[ i ].split( '=' );
            if ( pair[0] == parameterName ) {
                return pair[ 1 ];
            }
        }

    },

    getHost = function() {
        let host =
            window.location.protocol +
            "//" +
            window.location.host;
        return host;
    },

    getCurrentPath = () => window.location.pathname,

    saveCurrentPage = () => {
        sessionStorage.setItem( 'm360_current_page', window.location.pathname );
    };

    return {
        getRequestParameter : getRequestParameter,
        getRequestParameterValue : getRequestParameter,
        getHost : getHost,
        saveCurrentPage : saveCurrentPage ,
        getCurrentPath : getCurrentPath 
    };

})();

export { RequestUtil };
