/*
 * Module: Transitions
 *
 * @author UI Farm
 */

import { AnimationEvents } from './animationEvents';
import { EventManager } from '../util/eventManager';

const Transitions = (function() {

	let config = {
        transitionDataKey : 'data-css-transition',     
        transitionEventName : 'data-css-transition-evt'
    },
    
    init = function() {

        // Watch elements
        let elements = document.querySelectorAll( '[data-css-transition]' );
        if( elements && elements.length > 0 ) {
            elements.forEach(element => {
                addTransition( element );
            });
        }

    },

    addTransition = function( el ) {
        el.setAttribute( config.transitionDataKey, true );
        attachTransitionEnd( el );
    },

    isDoTransition = function( el ) {
        let data = el.getAttribute( config.transitionDataKey );
        if( data !== undefined && data ) {
            return true;
        }
        return false;
    },

	attachTransitionEnd = function( el ) {

        el.addEventListener( 
            AnimationEvents.getTransitionEndEvent(),
            function( e ) {
                if ( e.target == e.currentTarget ) {
                    if( isDoTransition( el ) ) {
                        EventManager.emit( 
                            el.getAttribute( config.transitionEventName ), 
                            el 
                        );
                    }
                }
            }
        );

    };

    // detachTransitionEnd = function( el ) {
    //     el.off( config.transitionEndEvents );
    // },

    // deleteTransitionData = function( el ) {
    //     if( el.data( config.transitionDataKey ) !== undefined )
    //         el.removeData( config.transitionDataKey );
    // };

    return {
        addTransitionEvent : addTransition,
        init : init
    }

})();

export { Transitions };