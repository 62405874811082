/*
 * Module: Subscribe
 *
 * @author Neil Palmer / UI Farm
 */

import $ from 'jquery';
import { ScrollTo } from '../../ui/scrollTo';

const SubscribeModal = ( function() {

    const APP_CONFIG = require('../../config/app-config.json');

    let config = {
        elements : {
            joinUs : $( '#handler-join-us' ),
            form : $( '.mc4wp-form' ),
            formSelector : '.mc4wp-form'
        }
    },

    init = function() {

        setJoinUsHandler();

    },

    // If subscribe form found on page scroll
    // Else return user to a subscribe page

    setJoinUsHandler = function() {
        config.elements.joinUs.on(
            'click.join-us',
            function( e ) {
                e.preventDefault();
                if( isSubscribeFormPresent() ) {
                    ScrollTo.doElementScroll( config.elements.formSelector );
                } else {
                    doRedirect();
                }
                return false;
            }
        )
    },

    doRedirect = function() {
        window.location.replace( '/subscribe' );
    },

    isSubscribeFormPresent = function() {
        return ( config.elements.form.length );
    };

    return {
        init : init
    };

})();

export { SubscribeModal };
