(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["listing-location.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"is-margin-bottom-1\">\n    <div class=\"tags has-addons is-margin-bottom-0\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "isLessOneMile")) {
output += "\n        <span class=\"tag\">Less than 1 mile away</span>\n        ";
;
}
else {
output += "\n        <span class=\"tag is-primary\">";
output += runtime.suppressValue(env.getFilter("round").call(context, runtime.contextOrFrameLookup(context, frame, "distance"),1), env.opts.autoescape);
output += "</span>\n        <span class=\"tag\">Miles Away</span>\n        ";
;
}
output += "\n    </div>  \n    <h2 class=\"title is-4 is-margin-bottom-0 is-margin-top-0\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "</h2>\n    <p class=\"subtitle is-margin-top-0 is-margin-bottom-0\">\n        ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "address"), env.opts.autoescape);
output += "\n    </p>\n</div>\n\n<div id=\"trials-";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\" class=\"location-trials-content\" data-location-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\">\n    <div class=\"image-cards\">\n        <div class=\"image-card is-light-card is-half\">\n            <div class=\"image-card-body\">\n                <p>\n                    <span class=\"tag card-placeholder card-placeholder-tag\"></span>\n                </p>\n                <p class=\"card-placeholder card-placeholder-title\"></p>\n                <p class=\"card-placeholder card-placeholder-meta\"></p>\n                <p class=\"card-placeholder card-placeholder-excerpt\"></p>\n            </div>\n            <div class=\"image-card-foot\">\n                <p>\n                    <span class=\"tag card-placeholder card-placeholder-tag\"></span>\n                </p>\n            </div>\n        </div>\n        <div class=\"image-card is-light-card is-half\">\n            <div class=\"image-card-body\">\n                <p>\n                    <span class=\"tag card-placeholder card-placeholder-tag\"></span>\n                </p>\n                <p class=\"card-placeholder card-placeholder-title\"></p>\n                <p class=\"card-placeholder card-placeholder-meta\"></p>\n                <p class=\"card-placeholder card-placeholder-excerpt\"></p>\n            </div>\n            <div class=\"image-card-foot\">\n                <p>\n                    <span class=\"tag card-placeholder card-placeholder-tag\"></span>\n                </p>\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

