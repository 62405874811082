/*
 * Module: ScrollTo
 *
 * @author Neil Palmer / UI Farm
 */
import $ from 'jquery';
import _ from 'underscore';
import { Fetch } from '../api/fetch';
import { EventManager } from '../util/eventManager';
import { RequestUtil } from '../util/requestUtil';
import { NunjucksEnv } from '../util/nunjucksEnv';
import { listings } from '../templates/listings';

const LazyListings = ( function() {

    const APP_CONFIG = require('../config/app-config.json');

    let config = {
        events : {
            trials : 'event.trials-by-condition'
        }
    };

    let doLazyListings = function() {

        var lazyListings;

        if( "IntersectionObserver" in window ) {

            lazyListings = document.querySelectorAll( ".listing-placeholder-content" );

            var listingObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach( function( entry ) {
                    if ( entry.isIntersecting ) {

                        let listing = entry.target,
                        conditionId = listing.dataset.conditionId,
                        locationId = listing.dataset.locationId;

                        Fetch.doFetch(
                            RequestUtil.getHost() + '/clinical-trials/location/trials-by-condition?locationId=' + locationId + '&conditionId=' + conditionId,
                            { method : 'get' },
                            config.events.trials
                        );

                        // Unobserve
                        listingObserver.unobserve( listing );

                    }
                });
            });

            lazyListings.forEach( function( listing ) {
              listingObserver.observe( listing );
            });

        } else {

            // Hide lazy loading listings
            $( '.listing-placeholder-content' ).each(function(i,v) {
                let listingEl = $( v ),
                    locationId = listingEl.data( 'location-id' );
                // Hide title
                $( '#location-title-' + locationId ).hide();
                listingEl.hide();
            });

        }

    },

    getTrialData = function( trials ) {
        return _.map(
            trials,
            function( item, key, list ) {
                let trialsCount = list.length,
                    isOddNumTrials = trialsCount%2,
                    className = trialsCount < 2 || ( isOddNumTrials && ((key+1) === trialsCount)) ? 'is-12' : 'is-half';
                item.cardClass = 'is-half';
                return item;
            }
        );
    },

    doListenRenderListings = function() {

        EventManager.on(
            config.events.trials,
            function( d ) {

                let trialData = getTrialData( d.trials ),
                    data = { trials : trialData },
                    html = false;

                try {
                    html = NunjucksEnv.get().render(
                        'listings.njk',
                        data
                    );
                } catch(err) { }

                if( html ) {
                    $( '#trials-' + d.locationId ).html( html );
                }

            }
        );

    },

    isListingsPage = function() {
        return ( $( 'body.ct-listings-pg' ).length !== 0 )
    },

    init = function() {

        if( isListingsPage() ) {

            // Render location trials
            doListenRenderListings();

            // Fire trial requests when in browser view
            doLazyListings();

        }

    };

    return {
        init : init
    };

})();

export { LazyListings };
