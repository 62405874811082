/*
 * Module: Latest Articles
 *
 * @author Neil Palmer / UI Farm
 */
import { Fetch } from '../api/fetch';
import { EventManager } from '../util/eventManager';
import { RequestUtil } from '../util/requestUtil';
import { NunjucksEnv } from '../util/nunjucksEnv';
import { LazyImages } from '../ui/lazyImages';
import { Logger } from '../util/logger';
import { DOMElements as DOMHelper } from '../dom/domElements';
import '../templates/articles';

const LatestArticles = ( function() {

    let config = {
        eventName : 'event.latest-posts',
        apiEndpoint : '/wp-json/mindcare360/latest-posts?num_posts=4',
        elements : []
    },

    initConfig = () => {
        config.elements.listingsContainer = 
            DOMHelper.get( '#mc_module_latest-articles_content' );
    },  

    init = () => {

        if( isLatestArticlesModule() ) {
            initConfig();
            doFetchArticles();
        }

    },

    doFetchArticles = () => {

        Fetch.doFetch(
            RequestUtil.getHost() + config.apiEndpoint,
            { method : 'get' },
            config.eventName
        );

        EventManager.on(
            config.eventName,
            function( d ) {
                renderArticles( d.posts );
            }
        );

    },

    getArticleHtml = ( posts) => {
        return NunjucksEnv.get().render(
            'articles.njk',
            { articles : posts }
        );
    },

    renderArticles = ( posts ) => {
        if( posts ) {
            Logger.log( 'Render articles', posts );
            DOMHelper.appendTo( 
                config.elements.listingsContainer, 
                getArticleHtml( posts ) 
            );
            LazyImages.addLazyImages();
        }
    },

    isLatestArticlesModule = () => DOMHelper.isPresent( '#mc_module_latest-articles' );
    
    return {
        init : init
    };

})();

export { LatestArticles };